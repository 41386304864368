<template>
  <div class="card-header text-end">
    <img src="@/assets/bg.png" class="image-bg" alt="image" />
    <div>
      <i @click="setProfile" class="bi bi-gear-fill icon"></i>
    </div>

    <div style="
        width: 135px;
        height: 60px;
        position: absolute;
        z-index: 1;
        left: 15%;
        bottom: 12%;
        transform: translate(-50%, -50%);
        text-align: center;
        background-color:transparent;
      ">
      <img src="@/assets/logo.png"
        style="width: 65%;  border-radius: 50%; border-style: solid;   border-color: white;  border-width: 2px;" />
    </div>


    <div class="row text-end mn-profile w-100 m-0">
      <span class="text-root mini-h text-black">ข้อเสนอแนะ &</span>
      <span class="text-root mini-h text-black">รับเรื่องร้องทุกข์ร้องเรียน</span>
    </div>

  </div>
</template>

<script>
import liff from "@line/liff";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Header",
  setup() {
    const AppName = process.env.VUE_APP_NAME;
    const setProfile = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_LIFF_REGISTER}`,
          external: false,
        });
      });
    };

    return {
      setProfile,
      AppName

    };
  },
});
</script>

<style scoped>

.card-header {
  background-color: var(--color);
  position: relative;
  height: auto;
  min-height: 220px !important;
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
}

.image-bg {
  filter: opacity(100%);
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

</style>
